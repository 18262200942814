/* eslint-disable consistent-return */
/* eslint-disable consistent-return */
/* eslint-disable vue/require-default-prop */
<template>
  <div
    class="pagination"
    :class="align"
  >
    <ul class="pagination-list rs-list">
      <li class="pagination-item">
        <button
          aria-label="Go to previous page"
          class="pagination-link rs-btn"
          type="button"
          :disabled="isInFirstPage"
          @click="onClickPreviousPage"
        >
          <feather-icon icon="ChevronLeftIcon" class="mr-50" />
        </button>
      </li>
      <li
        v-for="(page, i) in pages"
        :key="i"
        class="pagination-item"
      >
        <button
          class="pagination-link rs-btn"
          type="button"
          :disabled="page.isDisabled"
          :class="{ active: isPageActive(page.name) }"
          :aria-label="`Go to page number ${page.name}`"
          @click="onClickPage(page.name)"
        >{{ page.name }}</button>
      </li>
      <li class="pagination-item">
        <button
          class="pagination-link rs-btn"
          type="button"
          aria-label="Go to next page"
          :disabled="isInLastPage"
          @click="onClickNextPage"
        >
          <feather-icon icon="ChevronRightIcon" class="mr-50" />
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BasePagination',

  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: Array,
    },

    limit: {
      type: Number,
      required: false,
      default: 3,
    },

    perPage: {
      type: Number,
      required: true,
    },

    currentPage: {
      type: Number,
      required: true,
    },

    align: {
      type: String,
      default: 'center',
    },
  },

  data() {
    return {
      totalItems: 0,
    }
  },
  computed: {
    totalPages() {
      let pl = 0
      try {
        pl = Math.ceil(this.value.length / this.perPage)
      // eslint-disable-next-line no-empty
      } catch (error) {}
      return pl
    },

    startPage() {
      const temp = Math.ceil(this.limit / 2)

      if (this.currentPage <= temp) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.limit + 1
      }

      if (this.currentPage - temp > this.totalPages - this.limit + 1) {
        return this.totalPages - this.limit + 1
      }

      return this.currentPage - temp
    },
    // eslint-disable-next-line vue/return-in-computed-property
    endPage() {
      // const temp = false
      // if (this.totalPages && this.totalPages > 0) {
      //   return temp
      // }
      return this.totalPages && this.totalPages > 0 ? this.totalPages : 0
    },

    pages() {
      const range = []
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        })
      }
      return range
    },

    isInFirstPage() {
      return this.currentPage === 1
    },

    isInLastPage() {
      return this.currentPage === this.totalPages
    },
  },
  watch: {
    perPage(val) {
      if (val) {
        this.productList()
      }
    },
    value(val) {
      if (val) {
        this.productList()
      }
    },
    currentPage(val) {
      if (val) {
        this.productList()
      }
    },
  },
  mounted() {
    if (this.$route.query.page !== undefined) {
      this.isPageActive(this.$route.query.page)
    } else {
      this.isPageActive(1)
    }

    this.productList()
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },

    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },

    onClickPage(page) {
      this.$emit('pagechanged', page)
    },

    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },

    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },

    async productList() {
      // eslint-disable-next-line one-var
      // eslint-disable-next-line no-underscore-dangle
      const _data = await this.value
      // eslint-disable-next-line no-underscore-dangle
      let _start = 0
      // eslint-disable-next-line no-underscore-dangle
      let _end = this.currentPage * this.perPage
      // eslint-disable-next-line no-underscore-dangle
      const _list = []
      _start = this.currentPage < 1 ? 0 : (_start = _end - this.perPage)
      // eslint-disable-next-line no-const-assign
      _end = _end < _data.length ? _end : _data.length

      // eslint-disable-next-line no-plusplus
      for (let i = _start; i < _end; i++) {
        _list.push(_data[i])
      }
      this.$emit('items-per-page', _list)
    },

    isPageActive(page) {
      return this.currentPage === Number(page)
    },
  },

  // mounw
}
</script>

<style lang="sass" scoped>
.rs-btn
  display: inline-block
  padding: 0
  border: none
  background-color: transparent
.rs-list
  padding-left: 0
  margin-bottom: 0
  list-style: none
.pagination
  font-weight: 500
  display: flex
  align-items: center
  margin-top: 1.5rem

  &-list
    display: flex
    align-items: center
    // background-color: #fff
    // border: thin solid #fff

  &-link
    height: 2rem
    min-width: 2rem
    display: flex
    align-items: center
    justify-content: center

    &:not(:disabled)
      &:hover
        // color: var(--primary)
        background-color: var(--grey-5)

    &.active
      color: #fff
      border-radius: 5rem
      background-color: var(--primary)

    &:disabled
      cursor: default

  &.center
    justify-content: center

  &.right
    justify-content: flex-end
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // ...apps,
    // ...dashboard,
    ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// router.beforeEach((to, from, next) => {
//   const loggedIn = localStorage.getItem('BUSOtokenPlatform')
//   // const selectedSite = JSON.parse(localStorage.getItem('templateID')).id
//   if (to.path !== '/login') {
//     if (loggedIn) {
//       // if (to.path !== '/site') {
//       //   if (!selectedSite) {
//       //     next({
//       //       name: 'site',
//       //     })
//       //   }
//       // } else {
//       //   if (selectedSite) {
//       //     next({
//       //       name: 'dashboard-homepage',
//       //     })
//       //   }
//       //   next()
//       // }
//       next({
//         name: 'dashboard-homepage',
//       })
//     } else {
//       next({
//         name: 'auth-login',
//       })
//     }
//   } else {
//     if (loggedIn) {
//       // if (selectedSite) {
//       //   next({
//       //     name: 'dashboard-homepage',
//       //   })
//       // } else {
//       //   next({
//       //     name: 'site',
//       //   })
//       // }
//       next({
//         name: 'dashboard-homepage',
//       })
//     }
//     next()
//   }
//   next()
// })

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('BUSOtokenPlatform')
  const templateID = localStorage.getItem('templateID')
  const rolAdmin = ['platform-page-add', 'platform-page-list', 'platform-page-edit', 'component-add', 'component-list', 'component-edit']
  if (to.path !== '/login' && to.path !== '/register' && to.path !== '/me' && to.path !== '/site') {
    if (!loggedIn) {
      next({
        name: 'auth-login',
      })
    }
    if (loggedIn && !templateID) {
      next({
        name: 'site',
      })
    }

    if (loggedIn && JSON.parse(loggedIn).fullname !== 'Adamin Buso' && rolAdmin.includes(to.name)) {
      next({
        name: 'dashboard-homepage',
      })
    }
    next()
  } else {
    if (loggedIn && to.path !== '/me' && to.path !== '/site') {
      next({
        name: 'dashboard-homepage',
      })
    }

    if (to.path === '/site' && !loggedIn) {
      next({
        name: 'auth-login',
      })
    }
    next()
  }
  next()
})

export default router

// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

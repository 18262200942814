export default [
  {
    path: '/',
    name: 'dashboard-homepage',
    component: () => import('@/views/dashboard/homepage/Homepage.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/site',
    name: 'site',
    component: () => import('@/views/pages/authentication/Site.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/me',
    name: 'auth-me',
    component: () => import('@/views/pages/authentication/Me.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  /** ****************************\
    * Platform / Platform page
  \***************************** */
  {
    path: '/component/add',
    name: 'component-add',
    component: () => import('@/views/pages/component/ComponentAdd.vue'),
  },
  {
    path: '/component/list',
    name: 'component-list',
    component: () => import('@/views/pages/component/ComponentList.vue'),
  },
  {
    path: '/component/edit/:id',
    name: 'component-edit',
    component: () => import('@/views/pages/component/ComponentEdit.vue'),
    meta: {
      navActiveLink: 'component-list',
    },
  },
  {
    path: '/platform-page/add',
    name: 'platform-page-add',
    component: () => import('@/views/pages/platform_page/PPageAdd.vue'),
  },
  {
    path: '/platform-page/list',
    name: 'platform-page-list',
    component: () => import('@/views/pages/platform_page/PPageList.vue'),
  },
  {
    path: '/platform-page/edit/:id',
    name: 'platform-page-edit',
    component: () => import('@/views/pages/platform_page/PPageEdit.vue'),
    meta: {
      navActiveLink: 'platform-page-list',
    },
  },
  {
    path: '/platform-page/component/:id',
    name: 'platform-page-component',
    component: () => import('@/views/pages/platform_page/PPageComponent.vue'),
    meta: {
      navActiveLink: 'platform-page-list',
    },
  },
  /** ****************************\
    * Gallery / Gallery category
  \***************************** */
  {
    path: '/gallery_category/list',
    name: 'gallery-category-list',
    component: () => import('@/views/pages/gallery/GalleryCategoryList.vue'),
  },
  {
    path: '/gallery_category/add',
    name: 'gallery-category-add',
    component: () => import('@/views/pages/gallery/GalleryCategoryAdd.vue'),
  },
  {
    path: '/gallery_category/edit/:id',
    name: 'gallery-category-edit',
    component: () => import('@/views/pages/gallery/GalleryCategoryEdit.vue'),
    meta: {
      navActiveLink: 'gallery-category-list',
    },
  },
  {
    path: '/gallery_category/detail/:id',
    name: 'gallery-category-detail',
    component: () => import('@/views/pages/gallery/GalleryCategoryDetail.vue'),
    meta: {
      navActiveLink: 'gallery-category-list',
    },
  },
  {
    path: '/gallery/list',
    name: 'gallery-list',
    component: () => import('@/views/pages/gallery/GalleryList.vue'),
  },
  {
    path: '/gallery/add',
    name: 'gallery-add',
    component: () => import('@/views/pages/gallery/GalleryAdd.vue'),
  },
  {
    path: '/gallery/edit/:id',
    name: 'gallery-edit',
    component: () => import('@/views/pages/gallery/GalleryEdit.vue'),
    meta: {
      navActiveLink: 'gallery-list',
    },
  },
  {
    path: '/gallery/detail/:id',
    name: 'gallery-detail',
    component: () => import('@/views/pages/gallery/GalleryDetail.vue'),
    meta: {
      navActiveLink: 'gallery-list',
    },
  },
  /** ****************************\
    * Blog / Blog category
  \***************************** */
  {
    path: '/blog_category/list',
    name: 'blog-category-list',
    component: () => import('@/views/pages/blog/BlogCategoryList.vue'),
  },
  {
    path: '/blog_category/add',
    name: 'blog-category-add',
    component: () => import('@/views/pages/blog/BlogCategoryAdd.vue'),
  },
  {
    path: '/blog_category/edit/:id',
    name: 'blog-category-edit',
    component: () => import('@/views/pages/blog/BlogCategoryEdit.vue'),
    meta: {
      navActiveLink: 'blog-category-list',
    },
  },
  {
    path: '/blog_category/detail/:id',
    name: 'blog-category-detail',
    component: () => import('@/views/pages/blog/BlogCategoryDetail.vue'),
    meta: {
      navActiveLink: 'blog-category-list',
    },
  },
  {
    path: '/blog/list',
    name: 'blog-list',
    component: () => import('@/views/pages/blog/BlogList.vue'),
  },
  {
    path: '/blog/add',
    name: 'blog-add',
    component: () => import('@/views/pages/blog/BlogAdd.vue'),
  },
  {
    path: '/blog/edit/:id',
    name: 'blog-edit',
    component: () => import('@/views/pages/blog/BlogEdit.vue'),
    meta: {
      navActiveLink: 'blog-list',
    },
  },
  {
    path: '/blog/detail/:id',
    name: 'blog-detail',
    component: () => import('@/views/pages/blog/BlogDetail.vue'),
    meta: {
      navActiveLink: 'blog-list',
    },
  },
  /** ****************************\
    * Recruitment / Recruitment category
  \***************************** */
  {
    path: '/recruitment_category/list',
    name: 'recruitment-category-list',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryList.vue'),
  },
  {
    path: '/recruitment_category/add',
    name: 'recruitment-category-add',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryAdd.vue'),
  },
  {
    path: '/recruitment_category/edit/:id',
    name: 'recruitment-category-edit',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryEdit.vue'),
    meta: {
      navActiveLink: 'recruitment-category-list',
    },
  },
  {
    path: '/recruitment_category/detail/:id',
    name: 'recruitment-category-detail',
    component: () => import('@/views/pages/recruitment/RecruitmentCategoryDetail.vue'),
    meta: {
      navActiveLink: 'recruitment-category-list',
    },
  },
  {
    path: '/recruitment/list',
    name: 'recruitment-list',
    component: () => import('@/views/pages/recruitment/RecruitmentList.vue'),
  },
  {
    path: '/recruitment/add',
    name: 'recruitment-add',
    component: () => import('@/views/pages/recruitment/RecruitmentAdd.vue'),
  },
  {
    path: '/recruitment/edit/:id',
    name: 'recruitment-edit',
    component: () => import('@/views/pages/recruitment/RecruitmentEdit.vue'),
    meta: {
      navActiveLink: 'recruitment-list',
    },
  },
  {
    path: '/recruitment/detail/:id',
    name: 'recruitment-detail',
    component: () => import('@/views/pages/recruitment/RecruitmentDetail.vue'),
    meta: {
      navActiveLink: 'recruitment-list',
    },
  },
  /** ****************************\
    * Product
  \***************************** */
  {
    path: '/product_category/list',
    name: 'product-category-list',
    component: () => import('@/views/pages/product/ProductCategoryList.vue'),
  },
  {
    path: '/product_category/add',
    name: 'product-category-add',
    component: () => import('@/views/pages/product/ProductCategoryAddContainer.vue'),
  },
  {
    path: '/product_category/add/:id',
    name: 'product-category-add',
    component: () => import('@/views/pages/product/ProductCategoryAddContainer.vue'),
  },
  {
    path: '/product_category/edit/:id',
    name: 'product-category-edit',
    component: () => import('@/views/pages/product/ProductCategoryEditContainer.vue'),
    meta: {
      navActiveLink: 'product-category-list',
    },
  },
  {
    path: '/product_category/detail/:id',
    name: 'product-category-detail',
    component: () => import('@/views/pages/product/ProductCategoryDetail.vue'),
    meta: {
      navActiveLink: 'product-category-list',
    },
  },
  {
    path: '/product/list',
    name: 'product-list',
    component: () => import('@/views/pages/product/ProductList.vue'),
  },
  {
    path: '/product/add/',
    name: 'product-add',
    component: () => import('@/views/pages/product/ProductAdd.vue'),
  },
  {
    path: '/product/edit/:id',
    name: 'product-edit',
    component: () => import('@/views/pages/product/ProductEdit.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  {
    path: '/product/detail/:id',
    name: 'product-detail',
    component: () => import('@/views/pages/product/ProductDetail.vue'),
    meta: {
      navActiveLink: 'product-list',
    },
  },
  /** ****************************\
    * Warehouse
  \***************************** */
  {
    path: '/warehouse/list',
    name: 'warehouse-list',
    component: () => import('@/views/pages/warehouse/WareHouseList.vue'),
  },
  {
    path: '/warehouse/add',
    name: 'warehouse-add',
    component: () => import('@/views/pages/warehouse/WareHouseAdd.vue'),
  },
  {
    path: '/warehouse/detail/:id',
    name: 'warehouse-detail',
    component: () => import('@/views/pages/warehouse/WareHouseItem.vue'),
    meta: {
      navActiveLink: 'warehouse-list',
    },
  },
  /** ****************************\
    * Customer
  \***************************** */
  {
    path: '/customer/list',
    name: 'customer-list',
    component: () => import('@/views/pages/customer/CustomerList.vue'),
  },
  {
    path: '/customer/add',
    name: 'customer-add',
    component: () => import('@/views/pages/customer/CustomerAdd.vue'),
  },
  {
    path: '/customer/edit/:id',
    name: 'customer-edit',
    component: () => import('@/views/pages/customer/CustomerEdit.vue'),
    meta: {
      navActiveLink: 'customer-list',
    },
  },
  {
    path: '/customer/detail/:id',
    name: 'customer-detail',
    component: () => import('@/views/pages/customer/CustomerDetail.vue'),
    meta: {
      navActiveLink: 'customer-list',
    },
  },
  /** ****************************\
    * Customer Fake
  \***************************** */
  {
    path: '/customer-fake/list',
    name: 'customer-fake-list',
    component: () => import('@/views/pages/customerFake/CustomerFakeList.vue'),
  },
  {
    path: '/customer-fake/add',
    name: 'customer-fake-add',
    component: () => import('@/views/pages/customerFake/CustomerFakeAdd.vue'),
  },
  {
    path: '/customer-fake/edit/:id',
    name: 'customer-fake-edit',
    component: () => import('@/views/pages/customerFake/CustomerFakeEdit.vue'),
    meta: {
      navActiveLink: 'customer-fake-list',
    },
  },
  {
    path: '/customer-fake/detail/:id',
    name: 'customer-fake-detail',
    component: () => import('@/views/pages/customerFake/CustomerFakeDetail.vue'),
    meta: {
      navActiveLink: 'customer-fake-list',
    },
  },
  /** ****************************\
    * Attribute
  \***************************** */
  {
    path: '/attribute/add',
    name: 'attribute-add',
    component: () => import('@/views/pages/attribute/AttributeAdd.vue'),
  },
  {
    path: '/attribute/edit',
    name: 'attribute-edit',
    component: () => import('@/views/pages/attribute/AttributeEdit.vue'),
  },
  /** ****************************\
    * Trademark
  \***************************** */
  {
    path: '/trademark/list',
    name: 'trademark-list',
    component: () => import('@/views/pages/trademark/TrademarkList.vue'),
  },
  {
    path: '/trademark/add',
    name: 'trademark-add',
    component: () => import('@/views/pages/trademark/TrademarkAdd.vue'),
  },
  {
    path: '/trademark/edit/:id',
    name: 'trademark-edit',
    component: () => import('@/views/pages/trademark/TrademarkEdit.vue'),
    meta: {
      navActiveLink: 'trademark-list',
    },
  },
  {
    path: '/trademark/detail/:id',
    name: 'trademark-detail',
    component: () => import('@/views/pages/trademark/TrademarkDetail.vue'),
    meta: {
      navActiveLink: 'trademark-list',
    },
  },
  /** ****************************\
    * Origin
  \***************************** */
  {
    path: '/origin/list',
    name: 'origin-list',
    component: () => import('@/views/pages/origin/OriginList.vue'),
  },
  {
    path: '/origin/add',
    name: 'origin-add',
    component: () => import('@/views/pages/origin/OriginAdd.vue'),
  },
  {
    path: '/origin/edit/:id',
    name: 'origin-edit',
    component: () => import('@/views/pages/origin/OriginEdit.vue'),
    meta: {
      navActiveLink: 'origin-list',
    },
  },
  {
    path: '/origin/detail/:id',
    name: 'origin-detail',
    component: () => import('@/views/pages/origin/OriginDetail.vue'),
    meta: {
      navActiveLink: 'origin-list',
    },
  },
  /** ****************************\
    * Coupon
  \***************************** */
  {
    path: '/coupon/list',
    name: 'coupon-list',
    component: () => import('@/views/pages/coupon/CouponList.vue'),
  },
  {
    path: '/coupon/add',
    name: 'coupon-add',
    component: () => import('@/views/pages/coupon/CouponAdd.vue'),
  },
  {
    path: '/coupon/edit/:id',
    name: 'coupon-edit',
    component: () => import('@/views/pages/coupon/CouponEdit.vue'),
    meta: {
      navActiveLink: 'coupon-list',
    },
  },
  {
    path: '/coupon/detail/:id',
    name: 'coupon-detail',
    component: () => import('@/views/pages/coupon/CouponDetail.vue'),
    meta: {
      navActiveLink: 'coupon-list',
    },
  },
  /** ****************************\
    * Promotion
  \***************************** */
  {
    path: '/promotion/list',
    name: 'promotion-list',
    component: () => import('@/views/pages/promotion/PromotionList.vue'),
  },
  {
    path: '/promotion/add',
    name: 'promotion-add',
    component: () => import('@/views/pages/promotion/PromotionAdd.vue'),
  },
  {
    path: '/promotion/edit/:id',
    name: 'promotion-edit',
    component: () => import('@/views/pages/promotion/PromotionEdit.vue'),
    meta: {
      navActiveLink: 'promotion-list',
    },
  },
  {
    path: '/promotion/detail/:id',
    name: 'promotion-detail',
    component: () => import('@/views/pages/promotion/PromotionDetail.vue'),
    meta: {
      navActiveLink: 'promotion-list',
    },
  },
  /** ****************************\
    * Slider
  \***************************** */
  {
    path: '/slider/list',
    name: 'slider-list',
    component: () => import('@/views/pages/slider/SliderList.vue'),
  },
  {
    path: '/slider/add',
    name: 'slider-add',
    component: () => import('@/views/pages/slider/SliderAdd.vue'),
  },
  {
    path: '/slider/edit/:id',
    name: 'slider-edit',
    component: () => import('@/views/pages/slider/SliderEdit.vue'),
    meta: {
      navActiveLink: 'slider-list',
    },
  },
  {
    path: '/slider/detail/:id',
    name: 'slider-detail',
    component: () => import('@/views/pages/slider/SliderDetail.vue'),
    meta: {
      navActiveLink: 'slider-list',
    },
  },
  /** ****************************\
    * Supplier
  \***************************** */
  {
    path: '/supplier/list',
    name: 'supplier-list',
    component: () => import('@/views/pages/supplier/SupplierList.vue'),
  },
  {
    path: '/supplier/add',
    name: 'supplier-add',
    component: () => import('@/views/pages/supplier/SupplierAdd.vue'),
  },
  {
    path: '/supplier/edit/:id',
    name: 'supplier-edit',
    component: () => import('@/views/pages/supplier/SupplierEdit.vue'),
    meta: {
      navActiveLink: 'supplier-list',
    },
  },
  {
    path: '/supplier/detail/:id',
    name: 'supplier-detail',
    component: () => import('@/views/pages/supplier/SupplierDetail.vue'),
    meta: {
      navActiveLink: 'supplier-list',
    },
  },
  /** ****************************\
    * Service
  \***************************** */
  {
    path: '/service/list',
    name: 'service-list',
    component: () => import('@/views/pages/service/ServiceList.vue'),
  },
  {
    path: '/service/add',
    name: 'service-add',
    component: () => import('@/views/pages/service/ServiceAdd.vue'),
  },
  {
    path: '/service/edit/:id',
    name: 'service-edit',
    component: () => import('@/views/pages/service/ServiceEdit.vue'),
    meta: {
      navActiveLink: 'service-list',
    },
  },
  {
    path: '/service/detail/:id',
    name: 'service-detail',
    component: () => import('@/views/pages/service/ServiceDetail.vue'),
    meta: {
      navActiveLink: 'service-list',
    },
  },
  /** ****************************\
    * FAQ
  \***************************** */
  {
    path: '/faq/list',
    name: 'faq-list',
    component: () => import('@/views/pages/faq/FAQList.vue'),
  },
  {
    path: '/faq/add',
    name: 'faq-add',
    component: () => import('@/views/pages/faq/FAQAdd.vue'),
  },
  {
    path: '/faq/edit/:id',
    name: 'faq-edit',
    component: () => import('@/views/pages/faq/FAQEdit.vue'),
    meta: {
      navActiveLink: 'faq-list',
    },
  },
  {
    path: '/faq/detail/:id',
    name: 'faq-detail',
    component: () => import('@/views/pages/faq/FAQDetail.vue'),
    meta: {
      navActiveLink: 'faq-list',
    },
  },
  /** ****************************\
    * Contact
  \***************************** */
  {
    path: '/contact/list',
    name: 'contact-list',
    component: () => import('@/views/pages/contact/ContactList.vue'),
  },
  {
    path: '/contact/add',
    name: 'contact-add',
    component: () => import('@/views/pages/contact/ContactAdd.vue'),
  },
  {
    path: '/contact/edit/:id',
    name: 'contact-edit',
    component: () => import('@/views/pages/contact/ContactEdit.vue'),
    meta: {
      navActiveLink: 'contact-list',
    },
  },
  {
    path: '/contact/detail/:id',
    name: 'contact-detail',
    component: () => import('@/views/pages/contact/ContactDetail.vue'),
    meta: {
      navActiveLink: 'contact-list',
    },
  },
  /** ****************************\
    * Language
  \***************************** */
  // {
  //   path: '/language',
  //   name: 'language',
  //   component: () => import('@/views/pages/language/Language.vue'),
  // },
  /** ****************************\
    * Setting
  \***************************** */
  {
    path: '/config',
    name: 'config',
    component: () => import('@/views/pages/setting/Config.vue'),
  },
  /** ****************************\
    * Theme
  \***************************** */
  {
    path: '/theme-list',
    name: 'theme-list',
    component: () => import('@/views/pages/theme/ThemeList.vue'),
  },
]
